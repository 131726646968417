<template>
  <substance-allergies-template
    :substance-allergies="substanceAllergies"
    :other-substance-allergies="otherSubstanceAllergies"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import SubstanceAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/substance-allergies/SubstanceAllergiesTemplate';

import { SUBSTANCE_ALLERGY } from '@/modules/questionnaire/api/constants';

const fieldsToReset = ['cosmeticAllergies'];

const { requiredField, field, requiredArrayField } = fieldBuilder;

const FIELDS = [
  ...fieldsToReset.map(field),
  requiredArrayField('substanceAllergies'),
  requiredField('otherSubstanceAllergies')
];

export default {
  name: 'SubstanceAllergies',
  components: {
    SubstanceAllergiesTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    substanceAllergies(newValue, oldValue) {
      const hasCosmeticsGone =
        oldValue.includes(SUBSTANCE_ALLERGY.COSMETICS) &&
        !newValue.includes(SUBSTANCE_ALLERGY.COSMETICS);

      if (hasCosmeticsGone) {
        this.resetStoreFieldsByName(fieldsToReset);
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'otherSubstanceAllergies') {
        return this.substanceAllergies.includes('other');
      }

      return true;
    }
  }
};
</script>
